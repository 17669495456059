import {Currency} from "@/types/currency/currency";
import {PriceInterface} from "@/types/price/price.interface";

export class Price implements PriceInterface {
	currency: Currency;
	priceCurrency: number;
	priceEur: number;

	constructor(currency: Currency, priceCurrency: number, priceEur: number) {
		this.currency = currency;
		this.priceCurrency = priceCurrency;
		this.priceEur = priceEur;
	}
}