
import {defineComponent} from 'vue';
import DefaultIcon from "@/components/Icon/DefaultIcon.vue";

export default defineComponent({
	name: 'BtnEdit',
	components: {DefaultIcon},
	props: {
		cssClass: {
			type: String,
			default: " text-gray-700",
		},
		btnPosition: {
			type: String,
			default: "mr-14 -mt-1",
		},
		btnIcon: {
			type: String,
			default: "return_icon",
		},
	},
	emits: [],
	setup() {
		return {}
	}
})
