import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mt-2 ml-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DefaultIcon = _resolveComponent("DefaultIcon")

  return (_openBlock(), _createBlock("div", {
    class: [' float-right'] + ' ' + _ctx.btnPosition
  }, [
    _createVNode("div", {
      class: ['absolute z-10 cursor-pointer float-right w-9 h-9 rounded-3xl ' +
     'text-center justify-self-center ' +
      'bg-gray-200 dark:bg-gray-700 text-gray-500 text-2xl', _ctx.cssClass],
      title: "edit"
    }, [
      _createVNode("div", _hoisted_1, [
        _createVNode(_component_DefaultIcon, {
          icon: _ctx.btnIcon,
          "icon-height": "h-5",
          "icon-width": "w-5",
          "css-class": " dark:bg-gray-700 text-gray-500"
        }, null, 8, ["icon"])
      ])
    ], 2)
  ], 2))
}