import {CurrencyInterface} from "@/types/currency/currency.interface";

export class Currency implements CurrencyInterface {
	currencyCode: string;
	currencyName: string;
	eurExchangeRate: number;
	exchangeRateDate: Date;

	constructor(currencyCode: string, currencyName: string, eurExchangeRate: number, exchangeRateDate: Date) {
		this.currencyCode = currencyCode;
		this.currencyName = currencyName;
		this.eurExchangeRate = eurExchangeRate;
		this.exchangeRateDate = exchangeRateDate;
	}
}