
import {defineComponent} from 'vue';

export default defineComponent({
	name: 'DefaultSpinner',
	components: {},
	props: {
		showSpinner: {
			type: Boolean,
			default: false,
		},
		showSpinnerAlternativeText: {
			type: Boolean,
			default: false,
		},
		spinnerColor: {
			type: String,
			default: "border-bp-light",
		},
		spinnerSize: {
			type: String,
			default: "h-32 w-32",
		}
	},
	setup() {
		return {};
	}
})
