
import {defineComponent, ref} from 'vue';
import {Price} from "@/types/price/price";
import {Currency} from "@/types/currency/currency";
import CardShare from "@/components/Share/CardShare.vue";
import {BoostpackItemList} from "@/services/boostpack/boostpack_item_list.type";
import {BoostpackFactory} from "@/services/boostpack/boostpack_factory.type";
import DefaultButton from "@/components/Button/DefaultButton.vue";
import SubscribeEditDialog from "@/components/Modal/SubscribeEditDialog.vue";
import BoostpackEditDialog from "@/components/Modal/BoostpackEditDialog.vue";

export default defineComponent({
	name: 'DefaultBoostpackCardLayout',
	components: {BoostpackEditDialog, SubscribeEditDialog, DefaultButton},
	props: {
		boostpackId: {
			type: Number,
			default: 0,
		},
		boostpackReference: {
			type: String,
			default: null,
		},
		cardWidth: {
			type: String,
			default: "w-full",
		},
		cardHeight: {
			type: String,
			default: "",
		},
		bpMargin: {
			type: String,
			default: "mx-auto md:mx-4 m-4",
		},
		mobileCardWidth: {
			type: String,
			default: "",
		},
		cardColor: {
			type: String,
			default: "",
		},
		categoryTitle: {
			type: String,
			default: "Category",
		},
		boostpackTitle: {
			type: String,
			default: "Title",
		},
		boostpackItemList: {
			type: BoostpackItemList,
			default: null,
		},
		boostpackDate: {
			type: String,
			default: "365",
		},
		boostpackDateTitle: {
			type: String,
			default: "Days",
		},
		boostpackPeriod: {
			type: String,
			default: "Mo-Fr",
		},
		boostpackDescription: {
			type: String,
			default: "Description",
		},
		boostpackCreator: {
			type: String,
			default: "@boostpack.app",
		},
		boostpackPrice: {
			type: Price,
			default: new Price(new Currency("", "", 0, new Date()), 0, 0),
		},
		enableSharing: {
			type: Boolean,
			default: true,
		},
		enableScale: {
			type: Boolean,
			default: true,
		},
		enableEditSubscription: {
			type: Boolean,
			default: false,
		},
		enableEditBoostpack: {
			type: Boolean,
			default: false,
		},
	},
	setup(props) {
		//TODO load webp image without quote
		const boostpackTitleImage = ref();
		if (props.boostpackItemList && props.boostpackItemList.length > 0) {
			const boostpackFactory = new BoostpackFactory();
			boostpackTitleImage.value = boostpackFactory.createRandomTitleImageByBoostpackItemList(props.boostpackItemList);
		} else {
			boostpackTitleImage.value = "/img/test/coffee-5963334_1920.jpg";
		}

		/**
		 *
		 */
		function getBoostpackDetailReference(): string | number {
			if (props.boostpackReference !== null && props.boostpackReference?.length > 0) {
				return props.boostpackReference;
			} else {
				return props.boostpackId
			}
		}

		return {boostpackTitleImage, getBoostpackDetailReference};
	}
})
